
@font-face {
  font-family: Noto;
  src: url(./fonts/noto_sans/NotoSans-Bold.ttf);
}

body {
  margin: 0;
  overflow: hidden;
  background-color: #181147;
  font-family: Noto, sans-serif;
  font-size: 160%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("RG_VEGAS_OPEN_PRIMARY_GRADIENT_HERO.png");
  background-size: cover;
  background-repeat: no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
