@keyframes fadeOut {
    from {opacity: 100%}
    to  {opacity: 0;}
}
@keyframes fadeIn {
    from {opacity: 0}
    to  {opacity: 100%;}
}

table.matchTable.out {
    animation-name: fadeOut;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

table.matchTable.in {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
