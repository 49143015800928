.App {
  text-align: center;
  color: white;
}

.Header {
  text-align: left;
  background-color: transparent;
  font-family: transducer-extended, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 180%;
  padding: 1rem 1.5rem;
  text-transform: uppercase;
}
.Header img {
  float: right;
}
div.statusLine {
  font-size: 50%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table.matchTable {
  width: 98%;
  margin: 0 1%;
  border-collapse: separate;
  border-spacing: 0 1rem;
  position: absolute;
}

div.activation_code {
  width: 80%;
  margin: 4rem auto;
  text-align: center;
  background-color: white;
  padding: 4rem 0;
  opacity: 40%;
}
div.activation_code button {
  font-size: 90%;
  box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.19);
  margin: .5rem;
}
