tr.matchcard  {
    background-color: #282c34;
    padding: 1rem 0;
    text-align: left;
}
tr.matchcard td {
padding: .5rem 1rem;
    text-align: left;
}

span.stationname {
    display: inline-block;
    /*width: 90%;*/
    background-color: transparent;
    /*padding-left: 1rem;*/
    min-width: 12rem;
    text-transform: uppercase;
}

span.vs {

    color: gray;
}

span.seedNumber {
    font-size: 50%;
    color: gray;
}
